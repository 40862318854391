import { Quote } from '~/config/quotes'
import QuoteMarkClose from '../illustration/embed/quote-close.svg?react'
import QuoteMarkOpen from '../illustration/embed/quote-open.svg?react'

type QuoteProps = {
  quote: Quote
  hideCta?: boolean
}

export const QuoteBlock = ({ quote, hideCta }: QuoteProps) => {
  return (
    <div className="quote">
      <blockquote>
        <QuoteMarkOpen />
        <div className="quote-content">
          {quote.logo && (
            <div className="quote-logo">
              <img src={`${quote.logo}`} alt="" />
            </div>
          )}
          {quote.quote}
          <cite>&mdash; {quote.cite} </cite>

          {quote.cta && !hideCta && (
            <div className="quote-cta">
              <a href={quote.cta} className="call-to-action size-md primary">
                {quote.ctaLabel ? quote.ctaLabel : 'Read the case study'}
              </a>
            </div>
          )}
        </div>
        <QuoteMarkClose />
      </blockquote>
    </div>
  )
}
